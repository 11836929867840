import React, {useState} from "react";
import {Button, Col, Input, Modal, Row, Switch} from "antd";
import axios from "axios";
import {baseURL} from "../service/ApiService";
import TextArea from "antd/es/input/TextArea";
import {EditFilled} from "@ant-design/icons";

const GptPromptEditModal = ({buttonName, title, callback}) => {
    const [open, setOpen] = useState(false);
    const [currentPromptName, setCurrentPromptName] = useState("");
    const [currentChinesePrompt, setCurrentChinesePrompt] = useState("");
    const [currentEnglishPrompt, setCurrentEnglishPrompt] = useState("");
    const [enableJson, setEnableJson] = useState(false);
    const [currentSchema, setCurrentSchema] = useState("{}");

    const showModal = () => {
        setOpen(true)
    }
    const save = (id) => {
        const data = {
            id: id,
            description: currentPromptName.replace('\u2028', '').replace('\u2029', ''),
            promptChinese: currentChinesePrompt.replace('\u2028', '').replace('\u2029', ''),
            promptEnglish: currentEnglishPrompt.replace('\u2028', '').replace('\u2029', ''),
            jsonMode: enableJson,
            config: currentSchema.replace('\u2028', '').replace('\u2029', ''),
        }
        axios.post(`${baseURL}/api/prompt`, data).then(
            () => {
                callback();
                setOpen(false);
            }
        )
    }
    return (
        <>
            <Button type={"primary"} onClick={showModal} style={{display: 'flex', alignItems: 'center'}}>
                <EditFilled style={{marginRight: 8}}/> {/* Adjust margin as needed */}
                {buttonName}
            </Button>
            <Modal
                title={"Add New Prompt"}
                centered
                open={open}
                onOk={() => save(prompt.id)}
                onCancel={() => setOpen(false)}
                okText="Create"
                width={1000}
                aria-hidden={!open}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <h4>Name</h4>
                        <Input value={currentPromptName} onChange={e => setCurrentPromptName(e.target.value)}/>
                        <h4>Chinese Prompt</h4>
                        <TextArea autoSize={true} value={currentChinesePrompt}
                                  onChange={(e) => setCurrentChinesePrompt(e.target.value)}/>
                        <h4>English Prompt</h4>
                        <TextArea autoSize={true} value={currentEnglishPrompt}
                                  onChange={(e) => setCurrentEnglishPrompt(e.target.value)}/>
                    </Col>
                    <Col span={12}>
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                            <h4 style={{margin: 0, marginRight: '10px'}}>JSON Schema</h4>
                            <Switch
                                checked={enableJson}
                                onChange={setEnableJson}
                            />
                        </div>
                        {
                            enableJson ? <TextArea autoSize={true} value={currentSchema}
                                                   onChange={(e) => setCurrentSchema(e.target.value)}/> : ''
                        }
                    </Col>
                </Row>
            </Modal>
        </>
    )
}
export default GptPromptEditModal